.heading {
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    padding-bottom: 48px;
}

.calendarContainer {
    margin: 0 auto;
    width: 80%
}

.addNewEventModalContainer {
    padding-top: 32px;
    text-align: center;
}

.alertContainer {
    width: 80%;
    margin: 0 auto;
    padding: 10px 0;
}