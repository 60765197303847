
.inputContainer {
    text-align: center;
    margin: 0 auto;
}

.submitButtonContainer {
    text-align: center;
    margin: 0 auto;
}

.root {
    z-index: 5;
}